import React from "react"
import PropTypes from "prop-types"


const Section = (props) => {

  const {
      className,
      children,
      headingClassName,
      title,
      t,
      id,
      subtitle,
      subHeadingClassName
    } = props

  return(
    <section className={className} id={id}>
      {title &&
        <h2 className={headingClassName}>
          {t ? (title) : title}
        </h2>
      }

      {subtitle &&
        <h3 className={subHeadingClassName}>
          {t ? (subtitle) : subtitle}
        </h3>
      }

      {children}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
  headingClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subHeadingClassName: PropTypes.string
}

Section.defaultProps = {
  className: '',
  children: {},
  title:'',
  headingClassName: '',
  subtitle: '',
  subHeadingClassName: ''
}

export default Section
